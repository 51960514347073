<template>
  <div
      class="container mt-5">
    <div
        class="row justify-content-center">
      <div
          class="col-md-6">
        <div
            v-if="!loggedIn"
            id="registerCard"
            class="card">
          <div
              class="card-body">
            <h1 class="card-title text-center">
              Register</h1>
            <form
                @submit.prevent="register">
              <div
                  class="mb-3">
                <label
                    for="username"
                    class="form-label">Username</label>
                <input
                    v-model="username"
                    type="text"
                    class="form-control"
                    id="username"
                    required/>
                <small style="color: #e74c3c" id="usernames"></small>
              </div>
              <div
                  class="mb-3">
                <label
                    for="username"
                    class="form-label">E-Mail</label>
                <input
                    v-model="email"
                    type="text"
                    class="form-control"
                    id="email"
                    required/>
                <small style="color: #e74c3c" id="emails"></small>
              </div>

              <div
                  class="mb-3">
                <label
                    for="password"
                    class="form-label">Password</label>
                <input
                    v-model="password"
                    type="password"
                    class="form-control"
                    id="password"
                    required/>
                <small style="color: #e74c3c" id="passwords"></small>
              </div>
              <div
                  class="mb-3">
                <label
                    for="password"
                    class="form-label">Password
                  Again</label>
                <input
                    v-model="passwordagain"
                    type="password"
                    class="form-control"
                    id="password_confirmation"
                    required/>
              </div>
              <button
                  type="submit"
                  class="btn btn-primary"
                  style="width: 100%;">
                REGISTER
              </button>
            </form>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import axios
  from 'axios';


export default {
  data() {
    return {
      email: '',
      password: '',
      passwordagain: ''
    };
  },
  inject: ['apiUrl'],
  methods: {
    async register() {
      if (this.password === this.passwordagain) {
        try {
          const formData = new FormData();
          formData.append('username', this.username);
          formData.append('password', this.password);
          formData.append('email', this.email);
          formData.append('password_confirmation', this.passwordagain);
          let response = await axios.post(this.apiUrl + '/api/register', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            }
          });
          response = response.data;
          if (response.status === true) {
            this.$swal({
              icon: 'success',
              title: 'Successfully!',
              text: response.message,
              customClass: {
                container: 'dark-mode',
              },
              iconColor: '#00b894',
              confirmButtonColor: '#00b894',
            }).then(() => {
              this.$router.push('/login');
            });
          }
        } catch (error) {
          if (error.response.status === 422) {
            const usernamesElement = document.getElementById('usernames');
            const emails = document.getElementById('emails');
            const passwords = document.getElementById('passwords');
            if (error.response.data.errors.username) {

              usernamesElement.textContent = error.response.data.errors.username;

            } else {
              usernamesElement.textContent = '';
            }

            if (error.response.data.errors.email) {
              emails.textContent = error.response.data.errors.email;
            }else {
              emails.textContent = '';
            }

            if (error.response.data.errors.password) {
              passwords.textContent = error.response.data.errors.password;
            } else {
              passwords.textContent = ''
            }


          }
          console.error('Registration failed', error);
        }
      } else {
        this.$swal({
          icon: 'error',
          title: 'Oops...',
          text: 'Password Not Match',
        });
      }
    },
  },
};
</script>

<style>
#registerCard {
  background-color: #212529 !important;
  color: #fff;
}
</style>