// src/router/index.js
import {
    createRouter,
    createWebHistory
} from 'vue-router';
import AppHome
    from '../views/AppHome.vue';
import AppChecker
    from '../views/AppChecker.vue';
import AppShop
    from '../views/AppShop.vue';
import AppLogin
    from '../views/AppLogin.vue';
import AppRegister
    from '../views/AppRegister.vue';
import AppMycard
    from '../views/AppMycard.vue';
import AppAddmoney
    from '../views/AppAddmoney.vue';
import AppProfile
    from '../views/AppProfile.vue';
import AuthService
    from '../services/AuthService';
import SiteBuilder
    from "@/views/SiteBuilder.vue";
import BuyService
    from "@/views/BuyService.vue";

const routes = [
    {
        path: '/',
        name: 'AppHome',
        component: AppHome,
        meta: {
            requiresAuth: true,
            title: 'Masked Community - Dashboard'
        },
    },
    {
        path: '/Checker',
        name: 'AppChecker',
        component: AppChecker,
        meta: {
            requiresAuth: true,
            title: 'Masked Community - CC Check'
        },
    },
    {
        path: '/CCShop',
        name: 'AppShop',
        component: AppShop,
        meta: {
            requiresAuth: true,
            title: 'Masked Community - CC Shops'
        },
    },
    {
        path: '/sitebuilder',
        name: 'SiteBuilder',
        component: SiteBuilder,
        meta: {
            requiresAuth: true,
            title: 'Masked Community - Site Builder'
        },
    },
    {
        path: '/buy-services',
        name: 'buyService',
        component: BuyService,
        meta: {
            requiresAuth: true,
            title: 'Masked Community - Buy Service'
        },
    },
    {
        path: '/myCard',
        name: 'AppMycard',
        component: AppMycard,
        meta: {
            requiresAuth: true,
            title: 'Masked Community - My Cards'
        },
    },
    {
        path: '/addBalance',
        name: 'AppAddmoney',
        component: AppAddmoney,
        meta: {
            requiresAuth: true,
            title: 'Masked Community - Wallet Add Balance'
        },
    },
    {
        path: '/profile',
        name: 'AppProfile',
        component: AppProfile,
        meta: {
            requiresAuth: true,
            title: 'Masked Community - Profile'
        },
    },
    {
        path: '/login',
        name: 'AppLogin',
        component: AppLogin,
        meta: {
            requiresAuth: false,
            title: 'Masked Community - Login'
        },
    },
    {
        path: '/register',
        name: 'AppRegister',
        component: AppRegister,
        meta: {
            requiresAuth: false,
            title: 'Masked Community - Register'
        },
    },
    {
        path: '/:pathMatch(.*)*',
        component: AppHome
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});
//
const isAuthenticated = AuthService.isAuthenticated();


router.beforeEach((to, from, next) => {
    if (!isAuthenticated && to.path !== '/login' && to.path !== '/register') {
        next('/login');
    } else {
        next();
    }
});


export default router;
