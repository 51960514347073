<template>
  <header
      class="navbar navbar-expand-lg navbar-dark bg-dark">
    <div
        class="container">
      <router-link
          to="/"
          class="navbar-brand">
        <img
            width="130"
            src="../assets/masked.svg"
            alt="Logo"/>
      </router-link>
      <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation">
        <span
            class="navbar-toggler-icon"></span>
      </button>
      <div
          class="collapse navbar-collapse"
          id="navbarNav">
        <ul class="navbar-nav">
          <!-- ms-auto sınıfı ile navitem'leri sağa doğru itiyoruz -->
          <li v-if="loggedIn"
              class="nav-item">
            <router-link
                to="/"
                class="nav-link">
              Dashboard
            </router-link>
          </li>
          <li v-if="loggedIn"
              class="nav-item">
            <router-link
                to="/checker"
                class="nav-link">
              Checker
            </router-link>
          </li>
          <li v-if="loggedIn"
              class="nav-item">
            <router-link
                to="/CCShop"
                class="nav-link">
              CC
              Shop
            </router-link>
          </li>
          <!-- Diğer sayfa bağlantıları buraya eklenebilir -->
          <li v-if="loggedIn"
              class="nav-item dropdown">
            <a class="nav-link dropdown-toggle"
               href="#"
               id="navbarDropdown"
               role="button"
               data-bs-toggle="dropdown"
               aria-haspopup="true"
               aria-expanded="false"
               style="display: flex; align-items: center;">
              <div>
                Tools
              </div>
            </a>
            <div
                class="dropdown-menu"
                aria-labelledby="navbarDropdown">
              <router-link
                  to="/sitebuilder"
                  class="dropdown-item">
                Site
                Builder
              </router-link>
              <div class="dropdown-divider"></div>
            </div>
          </li>
        </ul>


        <!-- Profil İconu ve Menüsü -->
        <ul class="navbar-nav ms-auto">
          <li v-if="!loggedIn"
              class="nav-item">
            <router-link
                to="/login"
                class="nav-link">
              Login
            </router-link>
          </li>
          <li v-if="!loggedIn"
              class="nav-item">
            <router-link
                to="/register"
                class="nav-link">
              Register
            </router-link>
          </li>
          <li v-if="loggedIn"
              class="nav-item dropdown">
            <a class="nav-link dropdown-toggle"
               href="#"
               id="navbarDropdown"
               role="button"
               data-bs-toggle="dropdown"
               aria-haspopup="true"
               aria-expanded="false"
               style="display: flex; align-items: center;">
              <img
                  src="https://e7.pngegg.com/pngimages/475/353/png-clipart-guy-fawkes-mask-anonymous-security-hacker-mask-face-head.png"
                  alt="Profile"
                  class="rounded-circle"
                  width="40"
                  height="40"
                  style="margin-right: 10px;"
              />
              <div>
                <span
                    style="font-size: 14px;">{{
                    this.username
                  }}</span>
                <div
                    class="balance-container"
                    style="font-size: 12px;">
                  <span
                      class="balance-label">Balance: </span>
                  <span
                      class="balance-amount">${{
                      this.balance
                    }}</span>
                </div>
              </div>
            </a>
            <div
                class="dropdown-menu"
                aria-labelledby="navbarDropdown">
              <router-link
                  to="/profile"
                  class="dropdown-item">
                Profile
              </router-link>
              <router-link
                  to="/addBalance"
                  class="dropdown-item">
                Add
                Balance
              </router-link>
              <router-link
                  to="/MyCard"
                  class="dropdown-item">
                Purchase
                History
              </router-link>
              <div
                  class="dropdown-divider"></div>
              <a class="dropdown-item"
                 @click="logout">Logout</a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </header>
</template>


<script>
import {
  AuthMixin
} from '@/mixins/AuthMixin';

export default {
  mixins: [AuthMixin],
  name: 'AppHeader',
  data() {
    return {};
  }

};
</script>


<style
    scoped>
.balance-container {
  display: flex;
  align-items: center;
  margin-right: 20px; /* İstenilen boşluk miktarını ayarlayabilirsiniz */
}

.balance-label {
  color: #6C6F8B; /* Etiket rengi */
  margin-right: 5px; /* Etiket ile miktar arasındaki boşluk miktarını ayarlayabilirsiniz */
}

.balance-amount {
  color: #12D4B6; /* Bakiye miktarının rengi */
  font-weight: bold; /* Bakiye miktarının kalınlığı */
}

/* Header bileşeni için özel stiller buraya eklenebilir */
</style>
