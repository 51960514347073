
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import   './mixins/AuthMixin';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

import 'vue-loaders/dist/vue-loaders.css';
import VueLoaders from 'vue-loaders';

const apiUrl = 'https://app.maskedcommunity.io'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'



library.add(fas)



createApp(App)
    .provide('apiUrl',apiUrl)
    .use(router)
    .use(VueSweetalert2)
    .use(VueLoaders)

    .component('font-awesome-icon', FontAwesomeIcon)
    .mount('#app');
